import TimelineComp from '@/components/TimelineComp';
import { WithDefaultLayout } from '../components/DefautLayout';
import { Page } from '../types/Page';
import { Card } from 'antd';
import TermCondition from '@/components/TermCondition';
import TitleTemp from '@/components/TitleTemp';
import Workshop from '@/components/Workshop';
import Link from 'next/link';
import RegistrationButton from '@/components/RegistrationButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faComputer, faMedal, faPersonChalkboard, faRobot } from '@fortawesome/free-solid-svg-icons';
import PrizeCard from '@/components/HomeCard';

const IndexPage: Page = () => {


    const ButtonCategory = () => {
        return (
            <Link
                href="/iit-program"
                className="md:-bottom-1 bottom-0 absolute left-1/2 transform -translate-x-1/2 text-white bg-black hover:bg-white hover:text-defaultBlue font-medium rounded-lg md:text-lg text-sm px-6 py-2 text-center"
            >
                Show Detail
            </Link>
        )
    }

    const Home = () => {
        const commonStyles = {
            textShadow: "1px 1px 1px black",
        };
        const titleStyles = {
            textShadow: "2px 2px 2px #00B0F0",
        };
        const subTitleStyles = {
            ...titleStyles,
            textShadow: "3px 3px 3px #00B0F0",
        };

        return (
            <div className="relative mt-20 lg:mt-24 md:mt-12">
                <img alt="indexpict" className="w-full min-h-screen lg:min-h-0 lg:h-auto md:relative lg:relative flex bg-center md:bg-cover mx-auto" src="/images/home-bg.webp" />
                <div className="absolute inset-0 bg-gray-900 opacity-70"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4 md:px-0 lg:px-0">
                    <h1 className="text-lg md:text-xl xl:text-2xl mb-4 xl:mb-10" style={commonStyles}>Indonesia Innovative Technology (IIT) Challenge 2024</h1>
                    <h1 className='text-base md:text-lg xl:text-4xl mb-4 xl:mb-3' style={titleStyles}>Software Programming, Presentation,</h1>
                    <h1 className='text-base md:text-lg xl:text-4xl mb-4 xl:mb-10' style={titleStyles}>and Robot Race Category</h1>
                    <h1 className="text-base md:text-lg mb-4 xl:text-xl xl:mb-10" style={commonStyles}>For Elementary School, Junior High School, and Senior High School</h1>
                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faCalendarDays} className='mr-2' style={{ fontSize: "1.25rem" }} />
                        <h1 className="text-sm md:text-base xl:text-lg" style={commonStyles}>05 October 2024</h1>
                    </div>
                    <h1 className="text-sm md:text-base mb-4 xl:text-xl xl:mb-10" style={commonStyles}>At Universitas Kristen Krida Wacana (UKRIDA)</h1>
                    <h1 className="text-base md:text-lg mb-2 xl:text-4xl xl:mb-3" style={subTitleStyles}>Education Prizes Equivalent to</h1>
                    <h1 className="text-base md:text-lg xl:text-4xl xl:mb-10" style={subTitleStyles}>IDR 96.000.000 and UKRIDA Scholarship</h1>
                    <p className="text-xs md:text-base xl:text-base mb-2 mt-2" style={commonStyles}>Register Now!</p>
                    <p className="text-xs md:text-base xl:text-sm" style={commonStyles}>The winners will become Indonesia&apos;s representative in the World Innovative Technology (WIT) Challenge</p>
                    <p className="text-xs md:text-base xl:text-sm" style={commonStyles}>on 2-3 November 2024 at Chonnam National University, Yeosu-si, South Korea</p>
                    <div className="p-10 rounded-md flex flex-col justify-center">
                        <RegistrationButton />
                    </div>
                </div>
            </div>
        );
    };


    const Category = () => {
        return (
            <>
                <TitleTemp name="Category" />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:px-8">
                    <Card
                        className="p-4 rounded-md  hover:text-white hover:bg-defaultBlue focus:ring-4 focus:outline-none focus:ring-blue-300 border border-gray-500 md:border-none mx-auto w-full"
                    >
                        <FontAwesomeIcon icon={faRobot} style={{ fontSize: "2rem" }} className='mb-4' />
                        <h2 className='text-base md:text-lg lg:text-xl font-semibold hover:text-white'>Robot Race</h2>
                        <p className="mb-4 text-sm">/Team, Max 2 person</p>
                        <h3 className="text-lg font-semibold">IDR 350.000</h3>
                        <h3 className="text-base md:text-lg  font-medium">High School (Mix Junior and Senior)</h3>
                        <ul className="list-disc ml-4 text-sm">
                            <li>Obstacle Avoidance Driving Game </li>
                            <li>Sumo game</li>
                        </ul>
                        <ButtonCategory />
                    </Card>

                    <Card
                        className="p-4 rounded-md relative hover:text-white hover:bg-defaultBlue focus:ring-4 focus:outline-none focus:ring-blue-300  border border-gray-500 md:border-none mx-auto w-full"
                    >
                        <FontAwesomeIcon icon={faComputer} style={{ fontSize: "2rem" }} className='mb-4' />
                        <h2 className='text-base md:text-lg lg:text-xl font-semibold hover:text-white'>Software Programming</h2>
                        <p className="mb-4 text-sm">/Individu</p>
                        <h3 className="text-base md:text-lg  font-semibold mb-4">IDR 150.000</h3>
                        <hr className="my-4 border-gray-400" />
                        <h3 className='text-base md:text-lg  font-medium'>Junior High School</h3>
                        <h3 className='text-base md:text-lg  font-medium'>Senior High School</h3>
                        <ButtonCategory />
                    </Card>

                    <Card
                        className="p-4 rounded-md relative hover:text-white hover:bg-defaultBlue focus:ring-4 focus:outline-none focus:ring-blue-300  border border-gray-500 md:border-none mx-auto w-full"
                    >
                        <FontAwesomeIcon icon={faPersonChalkboard} style={{ fontSize: "2rem" }} className='mb-4' />
                        <h2 className='text-base md:text-lg lg:text-xl font-semibold hover:text-white'>Presentation</h2>
                        <p className=" mb-4 text-sm">/Team, Max 4 person</p>
                        <h3 className="text-lg font-semibold">IDR 500.000</h3>
                        <h3 className="text-base md:text-lg  font-medium">Elementary School</h3>
                        <ul className="list-disc ml-4 text-sm">
                            <li>Innovative Technology Challenge</li>
                        </ul>
                        <hr className="my-4 border-gray-400" />
                        <h3 className="text-base md:text-lg font-semibold">IDR 600.000</h3>
                        <h3 className="text-base md:text-lg font-medium">High School (Mix Junior and Senior)</h3>
                        <ul className="list-disc ml-4 md:mb-12 lg:mb-8 text-sm">
                            <li>Student Symposium Research Paper</li>
                            <li>Innovative Technology Challenge</li>
                        </ul>
                        <ButtonCategory />
                    </Card>
                </div>
            </>
        );
    }

    const Prize = () => {
        return (
            <div>
                <div className="text-center">
                    <FontAwesomeIcon icon={faMedal} className='mt-24' style={{ color: "#00B0F0", fontSize: "5rem" }} />
                </div>
                <h2 className="text-2xl md:text-4xl font-semibold text-center mb-12 mt-4 text-defaultBlue">IIT Challenge 2024 Prizes and Benefits</h2>
                <div className="mx-auto max-w-screen-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-14 lg:px-0 mx-auto">
                        <PrizeCard
                            title="Elementary School"
                            desc={[
                                'Medal',
                                'Certificate',
                                'Software Programming Bootcamp for 2 Months Total IDR 26.550.000',
                                'Robotic Race Workshop for WIT Challenge 2024',
                                'Subsidies WIT Challenge 2024 Registration Fee'
                            ]} />
                        <PrizeCard
                            title="Junior High School"
                            desc={[
                                'Medal',
                                'Certificate',
                                'Software Programming Bootcamp for 2 Months Total IDR 26.550.000',
                                'Robotic Race Workshop for WIT Challenge 2024', 'Software Programming Workshop for WIT Challenge 2024',
                                'Subsidies WIT Challenge 2024 Registration Fee'
                            ]} />
                        <PrizeCard
                            title="Senior High School"
                            desc={[
                                'Medal',
                                'Certificate',
                                'Software Programming Bootcamp for 2 Months Total IDR 26.550.000',
                                'Robotic Race Workshop for WIT Challenge 2024',
                                'Software Programming Workshop for WIT Challenge 2024',
                                'Subsidies WIT Challenge 2024 Registration Fee',
                                {
                                    item: 'UKRIDA Scholarship for all faculty',
                                    subItems: [
                                        'The scholarship is still valid for a maximum 3 years after Senior High School graduation.',
                                        'For the 1st place, they will get a full scholarship as an entry tuition fee and semester fee maximum until the 8th semester with a minimum 3.00 grade point average in the Electrical Engineering, Industrial Engineering, and Civil Engineering study program.',
                                        'For the 1st place, if the participant chooses the other study program, 1st place will get a free entry tuition fee and a 25% discount on semester fees until the 8th semester with a minimum 3.00 grade point average.',
                                        'For the 2nd place, you will get a 100% discount on entry tuition fees for all study program.',
                                        'For the 3rd place, you will get a 70% discount on entry tuition fees for all study program.',
                                        `For the all champions, specifically for the medical study program, will get the opportunity to use Ukridian's Family to get the special  entry tuition fee IDR 200.000.0000.`,
                                        'All scholarships must fulfil the additional requirements for specific study programs.',
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const WorkshopProgram = () => {
        return (
            <div
                className="bg-center bg-cover mt-24"
                style={{
                    backgroundImage: 'url("/images/workshop/workshop-bg.webp")',
                    minHeight: '700px',
                }}
            >
                <h2 className="text-2xl md:text-4xl font-semibold text-center mb-8 mt-8 text-white py-8">Workshop</h2>
                <Workshop />
            </div>
        );
    }



    const Render = () => {
        const dateTimeline = ['14 September 2024', '21 September 2024', '05 October 2024'];
        const descTimeline = ['Registration Close', 'Technical Meeting (Online)', 'IIT Challenge at Krida Wacana Christian University (UKRIDA)'];
        const colorTimeline = ['green', 'blue', 'blue', 'purple']
        return (
            <div>
                {Home()}
                <div className="container mx-auto">
                    {Category()}
                    {Prize()}
                </div>
                {WorkshopProgram()}
                <div className="container mx-auto">
                    <h2 className="text-2xl md:text-4xl font-semibold text-center mb-12 mt-24 text-defaultBlue">Timeline</h2>
                    <TimelineComp desc={descTimeline} dates={dateTimeline} colors={colorTimeline} showTitle={false} />
                    <TermCondition />
                </div>


            </div>
        );
    }

    return (
        <div>
            {Render()}
        </div>
    );
}

IndexPage.layout = WithDefaultLayout;
export default IndexPage;
