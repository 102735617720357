import React from 'react';
import { Card } from 'antd';

interface HomeCard {
    title: string,
    desc: (string | { item: string, subItems: string[] })[]
}

const PrizeCard: React.FC<HomeCard> = ({ title, desc }) => (
    <Card title={title} style={{ width: 300 }} className='bg-gray-300'>
        <ol className="list-decimal pl-4 text-gray-700">
            {desc.map((item, index) => (
                <React.Fragment key={index}>
                    {typeof item === 'string' ? (
                        <li className='mb-2 px-1'>{item}</li>
                    ) : (
                        <>
                            <li className='mb-2 px-1'>{item.item}</li>
                            <ul className="list-disc pl-5 text-gray-600">
                                {item.subItems.map((subItem, subIndex) => (
                                    <li key={subIndex} className='mb-1'>{subItem}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </React.Fragment>
            ))}
        </ol>
    </Card>
);

export default PrizeCard;
