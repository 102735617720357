import React from 'react';

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const ButtonWorkshop = () => {
    return (
        <button onClick={() => openInNewTab("https://link.accelist.com/Registration")} className="bg-white text-black hover:bg-defaultBlue hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-md text-sm md:text-base px-4 py-2">Registration</button>

    )
}

export default function Workshop() {
    return (
        <>
            <div className="container grid grid-cols-1  gap-4 px-8 mx-auto">
            <div className="p-4 relative flex bg-center bg-cover mb-8" style={{ backgroundImage: 'url("/images/workshop/workshop-programming.webp")' }}>
                    <div className="bg-gray-900 opacity-50 absolute inset-0"></div>
                    <div className="flex flex-col justify-between w-full text-white relative z-10">
                        <h3 className="text-xl font-semibold">Python Complete Package - Software Programming</h3>
                        <p className="text-xs">For Junior and Senior High School</p>
                        <p className="text-xs mb-4">Basic to advance Python learning</p>
                        <h3 className="text-sm font-medium">6x meeting @6-8 hours on Saturday</h3>
                        <p className="text-xs mb-4">Hybrid workshop for IIT Challenge Preparation</p>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <ButtonWorkshop />
                            </div>
                            <div className="flex flex-col"> 
                                <div className="flex justify-end">
                                    <h1 className="text-xl font-bold">IDR 9.800.000</h1>
                                </div>
                                <div className="flex justify-end text-end">
                                    <p className='text-sm font-bold'>/school, max 10 person</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 relative flex bg-center bg-cover" style={{ backgroundImage: 'url("/images/workshop/workshop-programming.webp")' }}>
                    <div className="bg-gray-900 opacity-50 absolute inset-0"></div>
                    <div className="flex flex-col justify-between w-full text-white relative z-10">
                        <h3 className="text-xl font-semibold">Python Exercise - Software Programming</h3>
                        <p className="text-xs mb-4">For Junior and Senior High School who have Python basic experience</p>
                        <h3 className="text-sm font-medium">2x meeting @6-8 hours on Saturday</h3>
                        <p className="text-xs mb-4">Hybrid workshop for IIT Challenge Preparation</p>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <ButtonWorkshop />
                            </div>
                            <div className="flex flex-col"> 
                                <div className="flex justify-end">
                                    <h1 className="text-xl font-bold">IDR 4.800.000</h1>
                                </div>
                                <div className="flex justify-end text-end">
                                    <p className='text-sm font-bold'>/school, max 10 person</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
