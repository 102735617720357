import React from 'react'

interface TitleProps {
    name: string
}

const TitleTemp: React.FC<TitleProps> = ({ name }) => {
    return (
        <h2 className="text-2xl md:text-4xl font-semibold text-center mb-12 mt-8 text-defaultBlue">{name}</h2>
    )
}

export default TitleTemp

