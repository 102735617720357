import React from 'react'
import TitleTemp from './TitleTemp';

export default function TermCondition() {
    return (
        <>
            <TitleTemp name='Terms & Condition'/>
            <div className='container mx-auto px-8 mb-5 text-sm md:text-base '>
                <h3 className='font-bold mb-2'>Active Students</h3>
                <p className='mb-6 text-gray-400'>
                    You must be actively enrolled as a student in your school to qualify as a participant in this competition. This means only those who are currently registered and actively participating in a formal education program are eligible to compete. Please ensure that you are enrolled as a student in your school and can provide evidence of your active student status when requested
                </p>
                <h3 className='font-bold mb-2'>Register On Registration Form</h3>
                <p className='mb-6 text-gray-400'>
                    Participants are required to register using the provided registration form. This is a crucial step to officially become a participant in the IITC Competition. Please ensure to fill out all the required information accurately and completely on the registration form. Only participants who have registered officially will be considered eligible to participate in the IITC Competition.
                </p>
                <h3 className='font-bold mb-2'>Registration Fee</h3>
                <p className='mb-6 text-gray-400'>
                    To complete your registration for the IITC Competition, participants are required to pay the registration fee by transferring the specified amount to the provided bank account. <span className='font-bold'>Please make the transfer to BCA account number 8833445888 under the name of PT Accelist Edukasi Indonesia</span>, as indicated in the registration instructions. Ensure that the transferred amount matches the nominal fee listed for the competition. Upon completing the transfer, please keep the transaction receipt as proof of payment, which may be required for verification purposes.
                </p>
                <h3 className='font-bold mb-2'>Join Group</h3>
                <p className='mb-6 text-gray-400'>
                    After completing the registration process for the IITC Competition, participants are required to join the designated WhatsApp group. This is a mandatory step to ensure effective communication and dissemination of important updates regarding the competition. The link to join the WhatsApp group will be  provided at the end of the registration form. Please make sure to join the group promptly to stay informed about all relevant information and  announcements related to the competition
                </p>
            </div>
        </>
    );
}
