import { Timeline } from 'antd';
import React from 'react'
import TitleTemp from './TitleTemp';

interface TimelineInterface {
    dates: string[];
    desc: string[];
    colors: string[];
    showTitle:boolean;
}

const TimelineComp: React.FC<TimelineInterface> = ({ dates, desc, colors,showTitle }) => {
    return (
        <>
        {showTitle && <TitleTemp name='Timeline' /> }
            <div className='text-sm md:text-base'>
                <Timeline mode="alternate">
                    {dates.map((date, index) => (
                        <Timeline.Item key={index} color={colors[index]}>
                            <div className="flex flex-col">
                                <strong>{date}</strong>
                                <p className="text-gray-500">{desc[index]}</p>
                            </div>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </div>
        </>
    );
}

export default TimelineComp;
